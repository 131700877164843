@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@200;400;600;700;800&display=swap");
@font-face {
  font-family: AwkwardKai;
  src: url(AwkwardKai.woff);
}

@media screen and (max-width: 1440px) {
  html {
    font-size: calc(0.7503898128898129rem + 0.20790020790020788vw);
  }
}

@media screen and (max-width: 1920px) {
  html {
    font-size: calc(0.5625rem + 0.41666666666666674vw);
  }
}
html {
  font-size: calc(0.5625rem + 0.41666666666666663vw);
}

body {
  margin: 0;
  font-family: "Chakra Petch", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.1;
}

h2 {
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 700;
}
h4 {
  font-size: 1.25rem;
  line-height: 1.4;
  font-weight: 700;
}
