@mixin sm {
  @media (min-width: 640px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin large {
  @media (min-width: 1024px) {
    @content;
  }
}

.footer {
  a:hover {
    color: #a476df;
  }
}

.home-page {
  .moai-title {
    font-size: 5.5rem;
  }
  .sandbox-container {
    max-width: 140px;
    @include sm {
      max-width: 220px;
    }
  }

  .moai-font {
    font-family: "AwkwardKai";
  }

  .section-title {
    font-family: "AwkwardKai";
    font-size: 3rem;
  }

  a:hover {
    color: #a476df;
  }

  .section-home-header {
    @media (max-width: 1024px) {
      background-image: url("../../image/ether-land.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 90vh;
      .land-image {
        display: none;
      }
    }
  }

  #about {
    .container {
      max-width: 1140px;
    }
  }

  .function-section {
    height: 1000px;
    @media (max-width: 768px) {
      height: 600px;
    }

    .special-card {
      background-color: #fde5d1;
      top: 3%;
      left: 50%;
      transform: translate(-20%);
      clip-path: polygon(7% 0, 100% 0, 100% 93%, 93% 100%, 0 100%, 0 7%);
      .title {
        border-bottom: 1px solid black;
      }
      @media (max-width: 768px) {
        left: 0%;
        top: 10%;
        width: 100%;
        transform: translate(0%);
      }
    }
  }

  .welcome {
    display: grid;
    column-gap: 2rem;
    grid-template-areas: "Area-2 Area";
    grid-template-columns: 0.75fr 1fr;
    grid-auto-columns: 1fr;
    grid-template-rows: auto;
  }

  .overlay-fade {
    position: absolute;
    left: 0%;
    right: 0%;
    z-index: 20;
    height: 80px;
    background-image: linear-gradient(180deg, #141414, rgba(10, 10, 10, 0));
  }
  .timeline-item {
    position: relative;
    z-index: 2;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-template-columns: 64px 1fr;
    grid-template-rows: auto;
    @include md {
      grid-template-columns: 1fr 180px 1fr;
    }
    .item-title {
      font-size: 2.5rem;

      grid-column-end: 3;
      grid-column-start: 2;
      grid-row-end: 2;
      grid-row-start: 1;

      @include md {
        position: sticky;
        text-align: right;
        grid-column-end: 2;
        grid-column-start: 1;
        grid-row-end: 2;
        grid-row-start: 1;
      }
    }
    .dot {
      grid-column-end: 2;
      grid-column-start: 1;
      grid-row-end: 3;
      grid-row-start: 1;
      @include md {
        grid-column-end: 3;
        grid-column-start: 2;
        grid-row-end: 3;
        grid-row-start: 1;
      }
    }
    .item-content {
      text-align: left;
      min-height: 380px;
    }

    &:nth-of-type(even) {
      @include md {
        .item-title {
          text-align: left;
          grid-column-end: 4;
          grid-column-start: 3;
        }
        .item-content {
          grid-column-end: 2;
          grid-column-start: 1;
        }
      }
    }
  }
  .admin-component {
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    grid-auto-columns: 1fr;
  }

  .partner-component {
    grid-template-columns: repeat(3, 1fr);
    @include sm {
      grid-template-columns: repeat(4, 1fr);
      gap: 3rem;
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(6, 1fr);
      gap: 3rem;
    }
    display: grid;
  }

  .partner-item:hover {
    opacity: 0.5;
  }

  .partner-item .content {
    .title {
      border-bottom: 0.5px solid white;
    }
  }

  .team-item {
    .name {
      border-bottom: 0.5px solid white;
    }
  }

  .team-component {
    grid-template-columns: repeat(1, 1fr);
    @include sm {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include md {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    // @media (min-width: 1024px) {
    //   grid-template-columns: repeat(3, minmax(0, 1fr));
    // }
    margin-top: 3rem;
    gap: 3rem;
    display: grid;
  }

  .sun {
    width: 26px;
    height: 27px;
  }
}
