.App .reveal-page {
  font-family: "AwkwardKai";

  .reveal-video {
    max-height: 80vh;
    width: 95vh;
    animation: fade 18s;
  }
  .modal-video {
    width: 80vh;
  }

  .modal-container {
    min-width: 60%;
    min-height: 95%;
    @media (max-width: 768px) {
      min-height: 60%;
    }
  }

  .reveal-pic {
    max-height: 80vh;
    max-width: 800px;
    animation: fadeIn 3s;
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .card {
    opacity: 0.9;
    padding: 5px 20px;
    .address {
      overflow-wrap: anywhere;
    }
  }

  .input {
    width: 100px;
  }

  input[type="text"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  input[type="text"]::-webkit-inner-spin-button,
  input[type="text"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  /* Firefox */
  input[type="text"] {
    -moz-appearance: textfield;
  }

  input[type="text"] {
    border-radius: 3px;
    max-width: 5rem;
    // padding: 0.5rem;
    border: solid #ddd;
    border-width: 0 2px;
    font-size: 1.2rem;
    // height: 3rem;
    text-align: center;
    font-weight: bold;
  }

  input {
    outline: 0;
    color: rgba(0, 0, 0, 0.87);
  }

  .claim-button {
    margin-right: 5%;
  }

  .main {
    width: 100%;
    position: fixed;
    height: calc(100% - 80px);

    @media (max-width: 768px) {
      top: 45px;
      height: calc(100% - 45px);
    }
    top: 80px;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 2;
  }
  .bg-image {
    position: fixed;
    background-image: url("../../image/ether-land.jpg");
    // background-color: rgb(49, 47, 47);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100vh;
    -webkit-filter: brightness(0.5);
    -moz-filter: brightness(0.5);
    -o-filter: brightness(0.5);
    -ms-filter: brightness(0.5);
    filter: brightness(0.5);
  }

  .section-title {
    font-family: "AwkwardKai";
    font-size: 4rem;
  }

  .moai-video,
  .pic {
    border-radius: 2.5rem;
    -webkit-transition: border-radius 1s;
    transition: border-radius 1s, box-shadow 1s;

    &.selected {
      border-radius: 999px;
      box-shadow: 5px 12px 22px 1px #f79afa;
    }
  }
  .pic:hover {
    // border-radius: 999px;
    filter: brightness(0.8);
  }

  .claim-bar {
    z-index: 10;
    bottom: 0;
    height: 10vh;
  }

  .button {
    border-radius: 0;
    padding: 0.25rem 1rem;
    background-color: white;
    color: black;
  }
  .button:hover {
    background-color: black;
    color: white;
  }
}
