.App {
  .nav-menu {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    .relative {
      display: flex;
      justify-content: center;
    }
  }
  .button {
    padding: 0.75rem 1.25rem;
    background-color: black;
    color: white;
    text-transform: uppercase;
    display: inline-block;
  }
  .button:hover {
    background-color: white;
    color: black;
  }
  .container-large {
    max-width: 80rem;
    margin: 0 auto;
  }
}

.header {
  .logo {
    width: 50px;
    @media (max-width: 768px) {
      width: 30px;
    }
  }
  font-family: "AwkwardKai";
  a {
    color: white;
  }
  a:hover {
    color: gray;
  }
  .button {
    padding: 0;
  }

  a {
    text-decoration: none;
    position: relative;
    display: block;
    font-size: 18px;
    text-align: center;
    text-justify: center;
    text-transform: uppercase;
    transition: color 0.1s, background-color 0.1s;
    @media (max-width: 600px) {
      font-size: 11px;
    }
  }
  a:hover {
    color: #a476df;
  }

  a::before {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    height: 3px;
    width: 100%;
    background-color: #a476df;
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    transition: color 0.1s, -webkit-transform 0.2s ease-out;
    transition: color 0.1s, transform 0.2s ease-out;
    transition: color 0.1s, transform 0.2s ease-out,
      -webkit-transform 0.2s ease-out;
  }

  a:hover::before {
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

html {
  scroll-behavior: smooth;
}
