.mint-page {
  .section-title {
    font-family: "AwkwardKai";
    font-size: 2rem;
  }

  background: #111827;
  /* Chrome, Safari, Edge, Opera */
  input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .number-input button {
    outline: none;
    -webkit-appearance: none;
    background-color: white;
    border: none;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    margin: 0;
    position: relative;
  }

  .number-input button:before,
  .number-input button:after {
    display: inline-block;
    position: absolute;
    content: "";
    width: 1rem;
    height: 2px;
    background-color: #363636;
    transform: translate(-50%, -50%);
  }
  .number-input button.plus:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  .number-input input[type="number"] {
    max-width: 5rem;
    padding: 0.5rem;
    border: solid #ddd;
    border-width: 0 2px;
    font-size: 1.2rem;
    height: 3rem;
    text-align: center;
    font-weight: bold;
  }

  input {
    outline: 0;
    color: rgba(0, 0, 0, 0.87);
  }

  .card {
    padding: 7px;
    .address {
      overflow-wrap: anywhere;
    }
  }

  .alert {
    bottom: -30px;
  }
}
